import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { motion, MotionValue, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import logo from '../assets/logo.png';
import box from '../assets/svg/box.svg';
import item from '../assets/svg/item.svg';
import mobileHeld from '../assets/svg/mobile-held.svg';

const Stage1 = ({ scrollYProgress }: { scrollYProgress: MotionValue<number> }) => {
  const yMobileHeld = useTransform(scrollYProgress, [0, 0.2], ['0%', '-100%']);
  const xMobileHeld = useTransform(scrollYProgress, [0, 0.2], ['0%', '-100%']);
  const opacityMobileHeld = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const yItem = useTransform(scrollYProgress, [0, 0.2], ['100%', '0%']);
  const opacityItem = useTransform(scrollYProgress, [0, 0.2], [0, 1]);

  return (
    <motion.div
      style={{
        opacity: useTransform(scrollYProgress, [0.18, 0.2], [1, 0]),
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Box sx={{ flex: '0 0 33%' }}>
        <Typography
          variant='h1'
          gutterBottom>
          Welcome to T3kob
        </Typography>
        <Typography sx={{ textAlign: 'justify' }}>
          Revolutionize Your Inventory Management with NFC & AI Technology
        </Typography>
      </Box>
      <Box
        sx={{
          flex: '0 0 67%',
          position: 'relative',
          height: '200px',
        }}>
        <motion.img
          src={mobileHeld}
          alt='Mobile held'
          style={{
            position: 'absolute',
            width: '30%',
            height: 'auto',
            left: 0,
            top: 140,
            x: xMobileHeld,
            y: yMobileHeld,
            opacity: opacityMobileHeld,
          }}
        />
        <motion.img
          src={item}
          alt='Item'
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '80px',
            height: 'auto',
            y: yItem,
            opacity: opacityItem,
          }}
        />
      </Box>
    </motion.div>
  );
};

const Stage2 = ({ scrollYProgress }: { scrollYProgress: MotionValue<number> }) => {
  const yItem = useTransform(scrollYProgress, [0.2, 0.4], ['0%', '-100%']);
  const opacityItem = useTransform(scrollYProgress, [0.2, 0.4], [1, 0]);
  const yBox = useTransform(scrollYProgress, [0.2, 0.4], ['100%', '0%']);
  const opacityBox = useTransform(scrollYProgress, [0.2, 0.4], [0, 1]);

  return (
    <motion.div
      style={{
        opacity: useTransform(scrollYProgress, [0.2, 0.38, 0.4], [0, 1, 0]),
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box sx={{ flex: '0 0 33%' }}>
        <Typography
          variant='h2'
          gutterBottom>
          1. Registering New Articles
        </Typography>
        <Typography sx={{ textAlign: 'justify' }}>
          Easily add any item to your inventory by attaching an NFC sticker. Simply scan the
          sticker, and your article is instantly registered in the system.
        </Typography>
      </Box>
      <Box
        sx={{
          flex: '0 0 67%',
          position: 'relative',
          height: '200px',
        }}>
        <motion.img
          src={item}
          alt='Item'
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '80px',
            height: 'auto',
            y: yItem,
            opacity: opacityItem,
          }}
        />
        <motion.img
          src={box}
          alt='Box'
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '150px',
            height: 'auto',
            y: yBox,
            opacity: opacityBox,
          }}
        />
      </Box>
    </motion.div>
  );
};

const Stage3 = ({ scrollYProgress }: { scrollYProgress: MotionValue<number> }) => {
  const yBox = useTransform(scrollYProgress, [0.4, 0.6], ['0%', '-100%']);
  const opacityBox = useTransform(scrollYProgress, [0.4, 0.6], [1, 0]);
  const yMobileHeld = useTransform(scrollYProgress, [0.4, 0.6], ['100%', '0%']);
  const opacityMobileHeld = useTransform(scrollYProgress, [0.4, 0.6], [0, 1]);

  return (
    <motion.div
      style={{
        opacity: useTransform(scrollYProgress, [0.4, 0.58, 0.6], [0, 1, 0]),
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box sx={{ flex: '0 0 33%' }}>
        <Typography
          variant='h2'
          gutterBottom>
          2. Registering New Containers
        </Typography>
        <Typography sx={{ textAlign: 'justify' }}>
          Transform any storage solution into a smart container. Attach an NFC sticker to any
          storage unit, scan it, and effortlessly register it as a container in your inventory
          system.
        </Typography>
      </Box>
      <Box
        sx={{
          flex: '0 0 67%',
          position: 'relative',
          height: '200px',
        }}>
        <motion.img
          src={box}
          alt='Box'
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '150px',
            height: 'auto',
            y: yBox,
            opacity: opacityBox,
          }}
        />
        <motion.img
          src={mobileHeld}
          alt='Mobile held'
          style={{
            position: 'absolute',
            width: '30%',
            height: 'auto',
            left: 0,
            top: 140,
            y: yMobileHeld,
            opacity: opacityMobileHeld,
          }}
        />
      </Box>
    </motion.div>
  );
};

const Stage4 = ({ scrollYProgress }: { scrollYProgress: MotionValue<number> }) => {
  const yMobileHeld = useTransform(scrollYProgress, [0.6, 0.8], ['0%', '-100%']);
  const opacityMobileHeld = useTransform(scrollYProgress, [0.6, 0.8], [1, 0]);
  const yBox = useTransform(scrollYProgress, [0.6, 0.8], ['100%', '0%']);
  const opacityBox = useTransform(scrollYProgress, [0.6, 0.8], [0, 1]);

  return (
    <motion.div
      style={{
        opacity: useTransform(scrollYProgress, [0.6, 0.78, 0.8], [0, 1, 0]),
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box sx={{ flex: '0 0 33%' }}>
        <Typography
          variant='h2'
          gutterBottom>
          3. Placing Articles Inside Containers
        </Typography>
        <Typography sx={{ textAlign: 'justify' }}>
          Streamline your organization process. Scan an article and then scan the desired container
          to place your item securely and efficiently.
        </Typography>
      </Box>
      <Box
        sx={{
          flex: '0 0 67%',
          position: 'relative',
          height: '200px',
        }}>
        <motion.img
          src={mobileHeld}
          alt='Mobile held'
          style={{
            position: 'absolute',
            width: '30%',
            height: 'auto',
            left: 0,
            top: 140,
            y: yMobileHeld,
            opacity: opacityMobileHeld,
          }}
        />
        <motion.img
          src={box}
          alt='Box'
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '150px',
            height: 'auto',
            y: yBox,
            opacity: opacityBox,
          }}
        />
      </Box>
    </motion.div>
  );
};

const Stage5 = ({ scrollYProgress }: { scrollYProgress: MotionValue<number> }) => {
  const yBox = useTransform(scrollYProgress, [0.8, 1], ['0%', '-50%']);
  const xBox = useTransform(scrollYProgress, [0.8, 1], ['0%', '50%']);
  const scaleBox = useTransform(scrollYProgress, [0.8, 1], [1, 1.5]);

  return (
    <motion.div
      style={{
        opacity: useTransform(scrollYProgress, [0.8, 0.82], [0, 1]),
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box sx={{ flex: '0 0 33%' }}>
        <Typography
          variant='h2'
          gutterBottom>
          4. View Detailed Information
        </Typography>
        <Typography sx={{ textAlign: 'justify' }}>
          Instantly access comprehensive details. Scan any article or container to display all
          relevant information, including contents and storage locations.
        </Typography>
      </Box>
      <Box sx={{ flex: '0 0 33%' }}>
        <Typography
          variant='h2'
          gutterBottom>
          5. Intelligent AI Assistance
        </Typography>
        <Typography sx={{ textAlign: 'justify' }}>
          Leverage AI to enhance your inventory management. Upload a photo of a new article, and our
          AI will automatically populate its details and suggest the most suitable container for
          optimal organization.
        </Typography>
      </Box>
      <Box
        sx={{
          flex: '0 0 34%',
          position: 'relative',
          height: '200px',
        }}>
        <motion.img
          src={box}
          alt='Box'
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '150px',
            height: 'auto',
            y: yBox,
            x: xBox,
            scale: scaleBox,
          }}
        />
      </Box>
    </motion.div>
  );
};

export default function MobileView() {
  const theme = useTheme();
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  });

  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        height: '500vh',
      }}>
      <motion.div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          padding: 2,
          zIndex: 1000,
          opacity: useTransform(scrollYProgress, [0, 0.1], [1, 0.2]),
        }}>
        <img
          src={logo}
          alt='Logo'
          style={{ height: '60px' }}
        />
      </motion.div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          position: 'sticky',
          top: 0,
          height: '100vh',
        }}>
        <Stage1 scrollYProgress={scrollYProgress} />
        <Stage2 scrollYProgress={scrollYProgress} />
        <Stage3 scrollYProgress={scrollYProgress} />
        <Stage4 scrollYProgress={scrollYProgress} />
        <Stage5 scrollYProgress={scrollYProgress} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          textAlign: 'center',
        }}>
        <Typography
          variant='h2'
          gutterBottom>
          Get Started with T3kob Today!
        </Typography>
        <Typography>
          Optimize your inventory management with cutting-edge NFC and AI technology.
        </Typography>
        <Box mt={4}>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '1.2rem',
            }}>
            Sign Up Now
          </motion.button>
        </Box>
      </Box>
    </Box>
  );
}
