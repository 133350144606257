import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, PaletteMode, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import MainContent from './components/MainContent';
import { getBlogTheme } from './theme';

export default function Blog() {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const blogTheme = createTheme(getBlogTheme(mode));
  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem('themeMode') as PaletteMode | null;
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);

  return (
    <ThemeProvider theme={blogTheme}>
      <CssBaseline enableColorScheme />
      <Container
        maxWidth='lg'
        component='main'
        sx={{ display: 'flex', flexDirection: 'column', my: 16, gap: 4 }}>
        <MainContent />
      </Container>
    </ThemeProvider>
  );
}
