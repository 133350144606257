import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import logo from '../assets/logo.png';
import box from '../assets/svg/box.svg';
import item from '../assets/svg/item.svg';
import mobileHeld from '../assets/svg/mobile-held.svg';
import shelves from '../assets/svg/shelves.svg';

export default function WebView() {
  const theme = useTheme();
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  });

  const yMobileHeld = useTransform(scrollYProgress, [0, 0.5], ['-100%', '0%']);
  const xMobileHeld = useTransform(scrollYProgress, [0, 0.5], ['0%', '100%']);
  const yItem = useTransform(scrollYProgress, [0, 0.5], ['-100%', '20px']);
  const xItem = useTransform(scrollYProgress, [0, 0.5], ['100%', '-20px']);
  const yBox = useTransform(scrollYProgress, [0, 0.5], ['100%', '200px']);
  const opacityFirstPage = useTransform(scrollYProgress, [0.4, 0.6], [1, 0]);
  const opacitySecondPage = useTransform(scrollYProgress, [0.4, 0.6], [0, 1]);
  const yShelves = useTransform(scrollYProgress, [0.5, 1], ['100%', '0%']);

  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        height: '300vh',
      }}>
      <motion.div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          padding: 2,
          zIndex: 1000,
          opacity: useTransform(scrollYProgress, [0, 0.1], [1, 0.2]),
        }}>
        <img
          src={logo}
          alt='Logo'
          style={{ height: '60px' }}
        />
      </motion.div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          position: 'sticky',
          top: 0,
          height: '100vh',
        }}>
        <motion.div
          style={{
            opacity: opacityFirstPage,
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            height: '100%',
          }}>
          <Box sx={{ flex: '0 0 33%' }}>
            <Typography
              variant='h1'
              gutterBottom>
              Let's talk
            </Typography>
            <Typography sx={{ textAlign: 'justify' }}>
              Our inventory management system uses NFC and AI to help you easily track and organize
              items, whether for your business or home. It reduces mistakes and saves time with
              real-time updates and automation. Perfect for anyone looking to stay organized and
              efficient.
            </Typography>
          </Box>
          <Box
            sx={{
              flex: '0 0 67%',
              position: 'relative',
              height: '300px',
            }}>
            <motion.img
              src={mobileHeld}
              alt='Mobile held'
              style={{
                position: 'absolute',
                width: '30%',
                height: 'auto',
                left: 0,
                top: 0,
                x: xMobileHeld,
                y: yMobileHeld,
              }}
            />
            <motion.img
              src={item}
              alt='Item'
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                width: '100px',
                height: 'auto',
                x: xItem,
                y: yItem,
              }}
            />
            <motion.img
              src={box}
              alt='Box'
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '200px',
                height: 'auto',
                y: yBox,
              }}
            />
          </Box>
        </motion.div>
        <motion.div
          style={{
            opacity: opacitySecondPage,
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}>
          <Box sx={{ flex: '0 0 33%' }}>
            <Typography
              variant='h1'
              gutterBottom>
              Organize with ease
            </Typography>
            <Typography sx={{ textAlign: 'justify' }}>
              Our system provides a visual representation of your inventory, making it easy to see
              what you have at a glance. With customizable shelves and categories, you can organize
              your items exactly how you want them.
            </Typography>
          </Box>
          <Box
            sx={{
              flex: '0 0 67%',
              position: 'relative',
              height: '300px',
            }}>
            <motion.img
              src={shelves}
              alt='Shelves'
              style={{
                position: 'absolute',
                width: '100%',
                height: 'auto',
                left: 0,
                bottom: 0,
                y: yShelves,
              }}
            />
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
}
